import { Routes, Route } from 'react-router-dom';
import './App.css';

import Index from './pages/Index';
import Shop from './pages/Shop';
import Challenge from './pages/Challenge';
import Leaderboard from './pages/Leaderboard';
import Playcity from './pages/Playcity';
import Menu from './pages/Menu';
import Reseach from './pages/research/Index';
import Design from './pages/design/Index';
import Build from './pages/Scrappybuild/Index';
import Game from './pages/Game';
import Video from './pages/Video';
import { Amplify } from 'aws-amplify';
import config from './aws-exports';
import Publish from './pages/Scrappybuild/Publish';
Amplify.configure(config);

function App() {
  return (
    <Routes>
      <Route exact path="/challenges/:challenge_id/:group_id/:student_id" element={<Index />} />
      <Route exact path='/shop' element={<Shop />} />
      <Route exact path='/challenge/urban-vertical-garden' element={<Challenge />} />
      <Route exact path='/leaderboard' element={<Leaderboard />} />
      <Route exact path='/playcity/:challenge_id/:group_id/:student_id' element={<Playcity />} />
      <Route exact path='/menu/:challenge_id/:group_id/:student_id' element={<Menu />} />
      <Route exact path='/research/:challenge_id/:group_id/:student_id' element={<Reseach />} />
      <Route exact path='/research/:paramChallengeId' element={<Reseach />} />
      <Route exact path='/design/:paramChallengeId' element={<Design />} />
      <Route exact path='/build/:paramChallengeId' element={<Build />} />
      <Route exact path='/game/:paramChallengeId' element={<Game />} />
      <Route exact path='/upload-video/:paramChallengeId' element={<Video />} />
      <Route exact path='/shop/:id' element={<Shop />} />
      {/* <Route exact path='/shop/bags' element={<Shop />} /> */}
      {/* <Route exact path='/shop/robots' element={<Shop />} />
      <Route exact path='/shop/shirts' element={<Shop />} />
      <Route exact path='/shop/books' element={<Shop />} /> */}
      <Route exact path='/publish/:paramChallengeId' element={<Publish />} />

    </Routes>
  );
}

export default App;
