import React from 'react'

const Leaderboard = () => {
  return (
    <div>
        <h1>Leaderboard</h1>
    </div>
  )
}

export default Leaderboard
